import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IEntitiesApiService } from '@bp/shared/models/metadata';
import { RecordsPage } from '@bp/shared/models/common';

import { BridgerPsp } from '@bp/frontend/domains/bridger-psps/core';
import { toHttpParams } from '@bp/frontend/utilities/common';
import { PaymentOptionType } from '@bp/frontend/models/business';

import { MerchantPsp, MerchantPspDTO } from '@bp/admins-shared/core/models';
import { BridgerPspsSharedFacade } from '@bp/admins-shared/domains/bridger-psps';

import { MerchantPspsQueryParams, PspsToAddToMerchantDTO } from '../models';

@Injectable({
	providedIn: 'root',
})
export class MerchantPspsApiService implements IEntitiesApiService<MerchantPsp, MerchantPspsQueryParams> {
	readonly collectionPath = 'merchant/psps';

	constructor(
		private readonly _bridgerPspsSharedFacade: BridgerPspsSharedFacade,
		private readonly _http: HttpClient,
	) {}

	readonly factory = (dto: MerchantPspDTO): MerchantPsp => new MerchantPsp(dto);

	getRecordsPage(query?: MerchantPspsQueryParams): Observable<RecordsPage<MerchantPsp>> {
		return zip(this._getRecordsPage(query), this._bridgerPspsSharedFacade.allByPspNameMap$).pipe(
			map(
				([ merchantPspRecordsPage, bridgerPspsByPspNameMap ]) => new RecordsPage({
					...merchantPspRecordsPage,
					records: merchantPspRecordsPage.records.map(
						merchantPsp => this.factory(this._assignBridgerPsp(
							merchantPsp,
							bridgerPspsByPspNameMap,
						)),
					),
				}),
			),
		);
	}

	private _getRecordsPage(query?: MerchantPspsQueryParams): Observable<RecordsPage<MerchantPsp>> {
		return this._http
			.get<RecordsPage<MerchantPspDTO>>(this.collectionPath, { params: toHttpParams(query) })
			.pipe(map(result => new RecordsPage(query, {
				...result,
				records: result.records
					.filter(merchantPsp => !PaymentOptionType.isDeprecated(merchantPsp.type))
					.sort((a, b) => a.pspName?.localeCompare(b.pspName ?? '') ?? 0),
			}, this.factory)));
	}

	get(id: string): Observable<MerchantPsp | null> {
		return zip(this._get(id), this._bridgerPspsSharedFacade.allByPspNameMap$).pipe(
			map(([ merchantPsp, bridgerPspsByPspNameMap ]) => merchantPsp ? this.factory(this._assignBridgerPsp(merchantPsp, bridgerPspsByPspNameMap)) : null),
		);
	}

	private _get(id: string): Observable<MerchantPsp | null> {
		return this._http
			.get<MerchantPspDTO | undefined>(`${ this.collectionPath }/${ id }`)
			.pipe(map(result => (result ? this.factory(result) : null)));
	}

	save(psp: MerchantPsp): Observable<MerchantPsp> {
		return zip(
			this._http.put<MerchantPspDTO>(`${ this.collectionPath }/${ psp.id }`, psp),
			this._bridgerPspsSharedFacade.allByPspNameMap$,
		).pipe(
			map(([ merchantPspDTO, bridgerPspsByPspNameMap ]) => this.factory(this._assignBridgerPsp(merchantPspDTO, bridgerPspsByPspNameMap))),
		);
	}

	delete(entity: MerchantPsp): Observable<void> {
		return this._http.delete<void>(`${ this.collectionPath }/${ entity.id }`);
	}

	addPsps(psps: PspsToAddToMerchantDTO): Observable<MerchantPsp[]> {
		return zip(
			this._http.post<MerchantPspDTO[]>(`${ this.collectionPath }/add`, psps),
			this._bridgerPspsSharedFacade.allByPspNameMap$,
		).pipe(
			map(([ merchantPspDTOs, bridgerPspsByPspNameMap ]) => merchantPspDTOs.map(merchantPspDTO => this.factory(this._assignBridgerPsp(merchantPspDTO, bridgerPspsByPspNameMap)))),
		);
	}

	private _assignBridgerPsp(
		merchantPspDTO: MerchantPspDTO,
		bridgerPspsByPspNameMap: Map<string, BridgerPsp>,
	): MerchantPspDTO {
		return {
			...merchantPspDTO,
			bridgerPsp: bridgerPspsByPspNameMap.get(merchantPspDTO.pspName!),
		};
	}
}
