import { Enumeration } from '@bp/shared/models/core/enum';

export class ListViewType extends Enumeration {

	static readonly grid = new ListViewType();

	static readonly table = new ListViewType();

	get isGrid(): boolean {
		return this === ListViewType.grid;
	}

	get isTable(): boolean {
		return this === ListViewType.table;
	}

}
