import { createFeatureSelector, createSelector } from '@ngrx/store';

import type { IState } from './identity-preferences.reducer';
import { FEATURE_STATE_KEY } from './identity-preferences.reducer';

export const selectFeatureState = createFeatureSelector<IState>(FEATURE_STATE_KEY);

export const selectIdentityPreferences = createSelector(
	selectFeatureState,
	state => state.userPreferences,
);
