import { Memoize } from 'typescript-memoize';
import { Observable, distinctUntilChanged, map } from 'rxjs';

import { Injectable, inject } from '@angular/core';

import { Store } from '@ngrx/store';

import { takePresent } from '@bp/frontend/rxjs';

import { IdentityPreferences, IdentityPreferencesKeys } from '@bp/admins-shared/domains/identity/models';

import { updateIdentityPreferences } from './identity-preferences.actions';
import { selectIdentityPreferences } from './identity-preferences.selectors';

@Injectable({ providedIn: 'root' })
export class IdentityPreferencesFacade {

	private readonly __store$ = inject(Store);

	preferences$ = this.__store$.select(selectIdentityPreferences);

	preferences!: IdentityPreferences | null;

	constructor() {
		this.__keepPlainPropertiesUpdated();
	}

	update(identityPreferences: Partial<IdentityPreferences>): void {
		this.__store$.dispatch(updateIdentityPreferences({
			identityPreferences: new IdentityPreferences({
				...this.preferences,
				...identityPreferences,
			}),
		}));
	}

	@Memoize((setting: IdentityPreferencesKeys) => `settingsChange_${ setting }`)
	settingChange$<TKey extends IdentityPreferencesKeys>(
		setting: TKey,
	): Observable<IdentityPreferences[TKey]> {
		return this.preferences$
			.pipe(
				takePresent,
				map(preferences => preferences[setting]),
				distinctUntilChanged(),
			);
	}

	private __keepPlainPropertiesUpdated(): void {
		this.preferences$
			.subscribe(identityPreferences => (this.preferences = identityPreferences));
	}

}
