import { Action, createReducer, on } from '@ngrx/store';

import { IdentityPreferences } from '@bp/admins-shared/domains/identity/models';

import { localStorageIdentityPreferencesChanged, updateIdentityPreferences } from './identity-preferences.actions';

export const FEATURE_STATE_KEY = '[domain]:identity-preferences';

export const IDENTITY_PREFERENCES_STATE_KEY = 'userPreferences'; // don't change the key value to keep users settings

export interface IState {
	[ IDENTITY_PREFERENCES_STATE_KEY ]: IdentityPreferences | null;
}

export const initialState: IState = {

	userPreferences: null,

};

const identityPreferencesReducer = createReducer(

	initialState,

	on(
		localStorageIdentityPreferencesChanged,
		updateIdentityPreferences,
		(state, { identityPreferences }): IState => ({
			...state,
			userPreferences: identityPreferences,
		}),
	),

);

export function reducer(state: IState | undefined, action: Action): IState {
	return identityPreferencesReducer(state, action);
}
