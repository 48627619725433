import { createAction, props } from '@ngrx/store';

import { IdentityPreferences } from '@bp/admins-shared/domains/identity/models';

import { IDENTITY_STATE_SCOPE as SCOPE } from '@bp/frontend-domains-identity';

export const updateIdentityPreferences = createAction(
	`${ SCOPE } Update Identity Preferences`,
	props<{ identityPreferences: IdentityPreferences }>(),
);

export const localStorageIdentityPreferencesChanged = createAction(
	`${ SCOPE } Local Storage Identity Preferences Changed`,
	props<{ identityPreferences: IdentityPreferences }>(),
);
