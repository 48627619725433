import moment from 'moment';

import {
	MapFromDTO, Control, Default, FieldControlType, Mapper, MetadataEntity, Label, FieldViewType, View, DTO
} from '@bp/shared/models/metadata';
import { NonFunctionPropertyNames } from '@bp/shared/typings';

import { Timezone, Timezones } from '@bp/frontend/features/moment/models';

import { ListViewType } from './list-view-type.enum';

export type IdentityPreferencesKeys = NonFunctionPropertyNames<IdentityPreferences>;

export type IdentityPreferencesDTO = DTO<IdentityPreferences>;

export class IdentityPreferences extends MetadataEntity {

	@Mapper(Timezone)
	@Control(
		FieldControlType.autocomplete,
		{
			list: Timezones.list,
			typeControlOptions: {
				suggestedItem: Timezones.guessed,
				suggestedItemButtonTextPrefix: 'Use your local timezone: ',
			},
		},
	)
	@View(
		FieldViewType.text,
		(timezone: Timezone) => `${ timezone.displayName }${ timezone === Timezones.guessed ? ' (Your local timezone)' : '' }`,
	)
	@Default(Timezone.UTC)
	@Label('Default Timezone')
	timezone!: Timezone;

	@Mapper(ListViewType)
	@Default(ListViewType.grid)
	merchantPspsListViewType!: ListViewType;

	@MapFromDTO()
	readonly timestamp!: number;

	constructor(dto?: IdentityPreferencesDTO) {
		super(dto);

		this.timestamp ||= moment().unix();
	}
}
